<template>
    <div class="page-user-settings">
        <mg-table :fields="fields" :apiUri="apiUri" :detailComponentName="detailComponentName" title="Benutzer">
            <template #table-header-left-content>
                <mg-icon name="user" class="mg-table-heading-icon"/>
                Benutzer
            </template>
            <template #table-header-right-content>
              <mg-button variant="primary" icon="add-button" @click="$router.push({ name: 'UserDetail' });">Benutzer erstellen</mg-button> 
            </template>
        </mg-table>
    </div>
</template>
<script>
export default {
    data: () => ({

        apiUri: '/user.php',
        detailComponentName: 'UserEdit',
        fields: [
            {
                key: 'username',
                label: 'Benutzername',
                class: ''
            },
            {
                key: 'name1',
                label: 'Name',
                class: ''
            },
            {
                key: 'created_at',
                label: 'Erstellt am',
                class: ''
            }
        ]

    }),
}
</script>