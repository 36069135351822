<template>
    <div class="page-login">
        <div class="container h-100">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-lg-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                Login
                            </div>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="login">
                                <label for="username">Benutzername</label>
                                <input type="text" class="form-control" name="username" id="username" v-model="user.username">

                                <label for="password">Passwort</label>
                                <input type="password" class="form-control" name="password" id="password" v-model="user.password">

                                <div class="row justify-content-center">
                                    <div class="col-auto">
                                        <button class="btn btn-primary mt-4" type="submit">Login</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    
    data: () => ({
        user: {
            username: 'mike',
            password: 'mike'
        }
    }),

    methods: {
        login() {
            axios
            .post(process.env.VUE_APP_ROOT_API + '/login.php', {
                username: this.user.username,
                password: this.user.password
            },
            {
                withCredentials: true
            })
            .then((response) => {
                console.log(response)
                if (!response.data.error) {
                    this.setCookie('identifier', response.data.identifier);
                    this.setCookie('securitytoken', response.data.securitytoken);
                    this.$router.push({ name: 'Home' })
                    this.$router.go();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },

        checkLogin() {
            if (this.$store.state.auth.user.isLoggedIn) {
                this.$router.push({ name: 'Home' });
            }
        },

        setCookie(name,value) {
            var expires = "";
            var date = new Date();
            date.setTime(date.getTime() + (365*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        },
    },

    mounted() {
        this.checkLogin();
    }

}
</script>