<template>
     <span class="mg-icon" v-html="svg"></span>
</template>

<script>
import axios from 'axios';
export default {
    name: 'mg-icon',

    data: () => ({
        svg: null,
    }),

    props: {
        name: {
            type: String,
            required: true
        }
    },

    methods: {

        getSvg() {
            const url = require(`./../assets/icons/${this.name}.svg`);
            axios
            .get(process.env.VUE_APP_CLIENT_URL + url)
            .then((response) => {
                this.svg = response.data;
            })
            .catch((err) => {
                console.log(err);
            });
        }
    },

    mounted() {
        this.getSvg();
  }
}
</script>
