<template>
    <div class="mg-table-container">
        <div class="container-fluid mg-table-header-container">
            <div class="row">
                <div class="col-6">
                    <div class="mg-table-heading">
                        <slot name="table-header-left-content"></slot>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <slot name="table-header-right-content"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <table class="table mg-table">
        <thead>
            <tr>
            <th scope="col">#</th>
            <template v-for="(field, idx1) in fields">
                <th :key="idx1">
                    {{ field.label }}
                </th>
            </template>
                <th class="mg-table-settings">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle mg-table-settings-btn" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                            <mg-icon name="menu" />
                        </button>
                        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                            <li >
                                <a class="dropdown-item" @click="refreshTableData()">
                                    <mg-icon name="refresh" class="dropdown-icon" />
                                    <span class="dropdown-item-text">
                                        Aktualisieren
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-if="isLoading">
                <div class="container">
                    <div class="row justify-content-center w-100vw">
                        <div class="col-auto">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <template v-for="(item, idx1) in items">
                    <tr class="mg-table-row" :key="idx1" @click.left.exact="onRowSelect(item.id, 'click')" @click.ctrl.left.exact="onRowSelect(item.id, 'ctrl_click')" :class="{ 'is-selected': selected.includes(item.id) }">
                        <td>
                            {{ idx1 + counterStart  }}
                        </td>
                        <template v-for="(field, idx2) in fields">
                            <td :key="idx2">
                                {{ item[field.key] }}
                            </td>
                        </template>
                        <td class="mg-table-edit-row">
                            <div class="dropdown">
                                <span class="dropdown-toggle mg-table-row-settings-btn" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                    <mg-icon name="three-dot" />
                                </span>
                                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                    <slot name="table-row-dropdown" :itemId="item.id">
                                        <li>
                                            <a class="dropdown-item" @click="$router.push({ name: detailComponentName, params: { id: item.id }  })">
                                                <mg-icon name="edit" class="dropdown-icon" />
                                                <span class="dropdown-item-text">
                                                    Bearbeiten
                                                </span>
                                            </a>
                                        </li>
                                    </slot>
                                    <li>
                                        <a class="dropdown-item" @click="confirmDeleteRow(item.id)">
                                            <mg-icon name="delete" class="dropdown-icon" />
                                            <span class="dropdown-item-text">
                                                Löschen
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </template>
        </tbody>
        </table>
        <div class="container-fluid">
            <div class="row">
                <div class="col-auto">
                    <span class="mg-table-total-text">
                        {{ totalItems }}
                        <template v-if="totalItems == 1">
                            Datensatz
                        </template>
                        <template v-else>
                            Datensätze
                        </template>
                    </span>
                </div>
            </div>
            <nav aria-label="Page navigation">
                <ul class="pagination mg-table-pagination">
                    <li class="page-item mg-page-item" :class="{ 'disabled' : activePage == 1}" :disabled="activePage == 1">
                    <a class="page-link mg-page-link" aria-label="Previous" @click="goToPage(activePage - 1)">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>
                    <li class="page-item mg-page-item" v-for="(page, idx1) in totalPages" :key="idx1" :class="{ 'active': page == activePage }">
                        <a class="page-link mg-page-link" @click="goToPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item mg-page-item" :class="{ 'disabled' : activePage == totalPages}" :disabled="activePage == totalPages">
                    <a class="page-link mg-page-link" aria-label="Next" @click="goToPage(activePage + 1)">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {

    data: () => ({

        isLoading: false,
        items: [],
        selected: [],
        selectedStartId: null,
        selectedEndId: null,
        activePage: 1,
        perPage: 10,
        counterStart: 0,
        totalItems: null,
        totalPages: null,

    }),

    props: {

        apiUri: {
            type: String,
            required: true
        },

        fields: {
            type: Array,
            required: true
        },

        detailComponentName: {
            type: String
        }

    },

    methods: {

        getTableData() {
            this.isLoading = true;
            var limit = this.perPage;
            var offset = this.activePage * this.perPage - this.perPage;
            axios
            .get(process.env.VUE_APP_ROOT_API + this.apiUri, {
                params: {
                    req: 'getTableData',
                    limit: limit,
                    offset: offset
                }
            })
            .then((response) => {
                if (response.data.tableData && response.data.total) {
                    this.items = response.data.tableData;
                    this.totalItems = response.data.total;
                    this.counterStart = this.activePage * this.perPage - this.perPage + 1;
                    this.calcPagination();
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

        calcPagination() {
            this.totalPages = Math.ceil(this.totalItems / this.perPage);
        },

        refreshTableData() {
            this.getTableData();
        },

        goToPage(page) {
            this.activePage = page;
        },

        confirmDeleteRow(deleteId) {
            this.$bvModal.msgBoxConfirm('Soll der Datensatz wirklich gelöscht werden?', {
                title: 'Löschen bestätigen',
                size: 'md',
                okVariant: 'danger',
                okTitle: 'Löschen',
                cancelTitle: 'Abbrechen',
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true
            })
            .then(confirmed => {
                if (confirmed) {
                    this.deleteRow(deleteId);
                }
            })
            .catch(err => {
                console.log(err);
            })
        },

        deleteRow(deleteId) {
            this.isLoading = true;
            axios
            .post(process.env.VUE_APP_ROOT_API + this.apiUri, {
                req: 'deleteTableRow',
                deleteId: deleteId
            })
            .then(() => {
                this.refreshTableData();
                this.$toasts.push({
                    type: 'success',
                    message: 'Datensatz erfolgreich gelöscht'
                })
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

        onRowSelect(id, click_mode) {
            if (click_mode == 'click') {
                this.selected = [];
                this.selectedStartId = id;
            }

            if (click_mode == 'ctrl_click' || click_mode == 'click') { 
                if (this.selected.includes(id)) {
                    const index = this.items.indexOf(id);
                    this.selected.splice(index, 1);
                } else {
                    this.selected.push(id);
                }
            }

        }

    },

    watch: {
        activePage: {
            handler: function(page) {
                this.activePage = page;
                this.getTableData();
            }
        }
    },

    mounted() {
        this.getTableData();
    }

}
</script>