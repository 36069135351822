<template>
<button @click="handleClick">
    <template v-if="icon">
        <mg-icon :name="icon" class="button-icon" />
    </template>
    <slot></slot>
</button>
</template>

<script>
export default {

    data: () => ({
        mgClass: '',
    }),
    props: {

        variant: {
            type: String,
            default: 'secondary'
        },

        icon: {
            type: String
        }

    },
    
    methods: {
        
        setClass() {
            var temp_class = this.$el.getAttribute('class');
            this.mgClass = 'btn btn-' + this.variant;
            if (temp_class) {
                this.mgClass += ' ' + temp_class;
            }
            this.$el.setAttribute('class', this.mgClass);
            
        },

        handleClick() {
            this.$emit('click')
        }

    },

    mounted() {
        this.setClass();
    }

}
</script>