<template>
    <div class="container-fluid mg-form-header-container">
        <div class="row mg-form-header-actions-row">
            <div class="col-6">
                <div class="col-auto">
                    <slot name="header-left-content"></slot>
                </div>
            </div>
            <div class="col-6">
                <div class="row justify-content-end">
                    <div class="col-auto">
                        <slot name="header-right-content"></slot>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-title">
            {{ title }}
        </div>
    </div>
</template>
<script>
export default {
    
    props: {
        title: {
            type: String
        }
    }

}
</script>