<template>
    <div id="page-wrapper" :class="pageWrapperClass">

        <div id="sidebar-wrapper">
            <!-- <div class="sidebar-heading">UI</div> -->
            <div class="row justify-content-center">
                <div class="col-auto sidebar-heading-col">
                    <mg-icon name="convert" class="sidebar-heading-icon" />
                </div>
            </div>
            <div class="list-group sidebar-nav">
                <router-link :to="{ name: 'Home' }" class="list-group-item sidebar-nav-link">
                   <mg-icon name="home" class="sidebar-nav-icon"></mg-icon>
                   <span class="sidebar-nav-link-text">
                       Home
                   </span>
                </router-link>
                <router-link :to="{ name: 'Conversions' }" class="list-group-item sidebar-nav-link">
                    <mg-icon name="convert-file" class="sidebar-nav-icon"></mg-icon>
                    <span class="sidebar-nav-link-text">
                       Konvertierungen
                    </span>
                    </router-link>
                <router-link :to="{ name: 'Settings' }" class="list-group-item sidebar-nav-link">
                    <mg-icon name="settings" class="sidebar-nav-icon"></mg-icon>
                    <span class="sidebar-nav-link-text">
                       Einstellungen
                    </span>
                </router-link>
            </div>
        </div>

        <div id="page-content-wrapper">

        <nav class="navbar navbar-expand-lg" id="navbar-top">
            <div class="container-fluid">
                <button class="btn btn-primary" id="menu-toggle" @click="toggleSidebar()">Toggle Menu</button>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTopDropdown" aria-controls="navbarTopDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarTopDropdown">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <mg-icon name="user-avatar" />
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end" aria-labelledby="navbarDarkDropdownMenuLink">
                            <li><a class="dropdown-item" @click="logout()">Logout</a></li>
                        </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- <slot name="page-content">
        </slot> -->
        <router-view />

        </div>

    </div>
</template>
<script>
import axios from 'axios';
export default {
    data: () => ({
        pageWrapperClass: '',
    }),

    methods: {

        toggleSidebar() {
            if (this.pageWrapperClass == '') {
                this.pageWrapperClass = 'toggled';
                return;
            }
            this.pageWrapperClass = '';
        },

        logout() {
            axios
            .post(process.env.VUE_APP_ROOT_API + '/auth.php', {
                req: 'logout',
                userId: this.$store.state.auth.user.id
            })
            .then(() => {
                this.deleteCookie('identifier');
                this.deleteCookie('securitytoken');
                this.$store.state.auth.user.id = null;
                this.$router.push({ name: 'Login' });
                this.$router.go();
            })
            .catch((err) => {
                console.log(err);
            });

        },

        deleteCookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    }
}
</script>