<template>
  <div
    :class="[
      `alert-${color}`,
      {
        'mb-4': position.includes('top'),
        'mt-4': position.includes('bottom')
      }
    ]"
    class="position-relative alert w-100 alert-dismissible"
    role="alert">

    <template v-if="title">
      <div class="alert-title">
        {{ title }}
      </div>
    </template>

    <div class="alert-message" :class="{ 'no-title': title == null }">
      {{ message }}
    </div>

    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      @click="$emit('remove')">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
</template>

<script>
import ToastMixin from '../../mixins/mg-toast/toast.mixin'
import TimerMixin from '../../mixins/mg-toast/timer.mixin'


export default {
  name: 'BootstrapComponent',

  mixins: [ToastMixin, TimerMixin],

  props: {
    title: {
      type: String,
      required: false
    }
  },

  computed: {
    color() {
      switch (this.type) {
        case 'base':
          return 'info'
        case 'primary':
          return 'primary'
        case 'warning':
          return 'warning'
        case 'error':
          return 'danger'
        case 'success':
          return 'success'
        default:
          return 'info'
      }
    }
  }
}
</script>

