<template>
    <div class="page-user-create">
        <form v-on:submit.prevent="saveUser()">

            <mg-form-header :title="pageTitle">
                <template #header-left-content>
                    <mg-button variant="secondary" @click="$router.push({ name: 'UserSettings' })">Zurück</mg-button>
                </template>
                <template #header-right-content>
                    <mg-button variant="success" type="submit">Speichern</mg-button>
                </template>
            </mg-form-header>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="username">Benutzername</label>
                                <input type="text" id="username" class="form-control" v-model="user.username" required="required">
                            </div>
                            <div class="col-md-6">
                                <label for="password">Passwort</label>
                                <input type="password" id="password" class="form-control" v-model="user.password" :required="editMode ? false : true">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="name1">Vorname</label>
                                <input type="text" id="name1" class="form-control" v-model="user.name1">
                            </div>
                            <div class="col-md-6">
                                <label for="name2">Nachname</label>
                                <input type="text" id="name2" class="form-control" v-model="user.name2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    
    data: () => ({

        pageTitle: 'Benutzer erstellen',
        isLoading: false,
        editMode: false,
        user: {
            id: null,
            username: '',
            password: '',
            name1: '',
            name2: ''
        }

    }),

    methods: {

        init() {
            if (this.$route.params.id) {
                this.pageTitle = 'Benutzer bearbeiten';
                this.editMode = true;
                this.getUser();          
            }
        },

        getUser() {
            this.isLoading = true;
            axios
            .get(process.env.VUE_APP_ROOT_API + '/user.php', {
                params: {
                    req: 'getUser',
                    userId: this.$route.params.id
                }
            })
            .then((response) => {
                this.user = response.data[0];
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

        saveUser() {
            this.isLoading = true;
            axios
            .post(process.env.VUE_APP_ROOT_API + '/user.php', {
                req: 'saveUser',
                user: this.user
            })
            .then((response) => {
                if (response.data.lastInsertId > 0) {
                    this.$router.push({ name: 'UserEdit', params: { id: response.data.lastInsertId } });
                }
                this.$toasts.push({
                    type: 'success',
                    message: 'Datensatz erfolgreich gespeichert'
                })
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        }

    },

    mounted() {
        this.init();
    }

}
</script>