<template>
    <div class="page-settings">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="settings-box">
                                <router-link :to="{ name: 'UserSettings' }" class="settings-box-link">
                                    <mg-icon name="user" class="settings-box-icon" />
                                    <div class="settings-box-title">
                                        Benutzer
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    
    data: () => ({
        isLoading: false,
        settings: {}
    }),

    methods: {

        getSettings() {
            this.isLoading = true;
            axios
            .get(process.env.VUE_APP_ROOT_API + '/settings.php', {
                params: {
                    req: 'getSettings'
                }
            })
            .then((response) => {
                this.settings = response.data;
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        }

    },

    mounted() {
        this.getSettings();
    }

}
</script>