<template>
<div>

    <mg-table :fields="fields" :apiUri="apiUri" :detailComponentName="detailComponentName">

        <template #table-header-left-content>
            <mg-icon name="convert-file" class="mg-table-heading-icon"/>
            Konvertierungen
        </template>
        <template #table-header-right-content>
            <mg-button variant="primary" icon="add-button" @click="$router.push({ name: 'ConversionsCreate' });">Konvertierung erstellen</mg-button> 
        </template>
        <template #table-row-dropdown="{ itemId }">
            <li>
                <a class="dropdown-item" @click="getFile(itemId)">
                    <mg-icon name="download" class="dropdown-icon" />
                    <span class="dropdown-item-text">
                        Download
                    </span>
                </a>
            </li>
        </template>
    </mg-table>

</div>
</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
export default {
    
    data: () => ({

        apiUri: '/convert.php',
        detailComponentName: 'ConversionCreate',
        fileName: null,
        xlsxContent: null,
        fields: [
            {
                key: 'name',
                label: 'Name',
                class: ''
            },
            {
                key: 'created_at',
                label: 'Erstellt am',
                class: ''
            }
        ]
    }),

    methods: {
        
        getFile(fileId) {
            axios
            .get(process.env.VUE_APP_ROOT_API + '/convert.php', {
                params: {
                    req: 'downloadFile',
                    fileId: fileId
                }
            })
            .then((response) => {
                this.fileName = response.data.name
                this.xlsxContent = response.data.content;
                this.downloadFile();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

        downloadFile() {
            var content = XLSX.utils.json_to_sheet(this.xlsxContent) 
            var wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, content, 'converter')
            XLSX.writeFile(wb, this.fileName)
        }

    }

}
</script>