<template>
  <div class="page-home">
    <div class="container-fluid">
    </div>    
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
