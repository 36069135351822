<template>

    <div class="container py-3">
        <template v-if="isLoading">
            <div class="row justify-content-center py-5">
                <div class="col-auto">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <form @submit.prevent="convert()">
                        <div class="card mg-converter-card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="card-title">
                                            Txt Datei Konvertieren
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row justify-content-end" v-if="this.fileContent">
                                            <div class="col-auto">
                                                <mg-button variant="primary" type="submit">Konvertieren</mg-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="py-3">
                                    <input type="file" class="form-control" name="file" id="file" @change="onFileSelect">
                                </div>
                                <textarea :disabled="textareaIsDisabled" name="fileContent" id="fileContent" class="form-control" rows="10" v-model="fileContent"></textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </template>
    </div>

</template>

<script>
import axios from 'axios';
import XLSX from 'xlsx';
export default {
    
    data: () => ({
        isLoading: false,
        fileContent: null,
        fileName: null,
        xlsxContent: null,
        textareaIsDisabled: true,
    }),

    methods: {

        onFileSelect(e) {
            const reader = new FileReader();
            const file = e.target.files[0];
            reader.onload = e => this.fileContent = e.target.result;
            this.fileName = e.target.files[0].name.replace('.txt','.xlsx');
            this.textareaIsDisabled = false;
            reader.readAsText(file)
        },

        convert() {
            this.isLoading = true;
            this.convertToXLSX();
            this.createXLSX();
            this.isLoading = false;
        },

        convertToXLSX() {
            var fileContent = document.getElementById("fileContent");
            var arrayOfLines = fileContent.value.split("\n");
            var xlsxContent = [[]];
            var counter = -1;
            arrayOfLines.forEach(function callback(value) {
                if (value.startsWith("Teil N")) {
                    counter++;
                    xlsxContent[counter] = {};
                    xlsxContent[counter]['name'] = value;
                    return;
                }
                if (value.includes("DIA_MED")) {
                    var typeRow = [];
                    typeRow = value.split('|');
                    xlsxContent[counter]['type'] = typeRow[0].trim();
                    xlsxContent[counter]['step-1'] = typeRow[1].trim();
                    xlsxContent[counter]['step-2'] = typeRow[2].trim();
                    xlsxContent[counter]['step-3'] = typeRow[3].trim();
                }
                if (value.includes("POS")) {
                    var _name = xlsxContent[counter]['name'];
                    counter++;
                    typeRow = value.split('|');
                    xlsxContent[counter] = {};
                    xlsxContent[counter]['name'] = _name;
                    xlsxContent[counter]['type'] = typeRow[0].trim();
                    xlsxContent[counter]['step-1'] = typeRow[1].trim();
                    xlsxContent[counter]['step-2'] = typeRow[2].trim();
                    xlsxContent[counter]['step-3'] = typeRow[3].trim();
                }
            })
            this.xlsxContent = xlsxContent;
        },

        createXLSX() {
            axios
            .post(process.env.VUE_APP_ROOT_API + '/convert.php', {
                req: 'saveFile',
                xlsxContent: this.xlsxContent,
                fileName: this.fileName
            })
            .then(() => {
                this.downloadXLSX();
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

        downloadXLSX() {
            var content = XLSX.utils.json_to_sheet(this.xlsxContent) 
            var wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, content, 'converter')
            XLSX.writeFile(wb, this.fileName)
        }

    }

}
</script>