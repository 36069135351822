import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './../views/Home.vue';
import Conversions from './../views/Conversions/Conversions.vue';
import ConversionsCreate from './../views/Conversions/ConversionsCreate.vue';
import Login from './../views/Login.vue';
import Settings from './../views/Settings/Settings.vue';
import UserSettings from './../views/Settings/UserSettings.vue';
import UserDetail from './../views/Settings/UserDetail.vue';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/conversions',
    name: 'Conversions',
    component: Conversions
  },
  {
    path: '/conversions/create',
    name: 'ConversionsCreate',
    component: ConversionsCreate
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/settings/users',
    name: 'UserSettings',
    component: UserSettings
  },
  {
    path: '/settings/users/new',
    name: 'UserDetail',
    component: UserDetail
  },
  {
    path: '/settings/users/edit/:id',
    name: 'UserEdit',
    component: UserDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
