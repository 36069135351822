module.exports = {
    props: {
      id: {
        type: String,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      type: {
        type: String,
        required: false,
        default: 'primary',
        validator: (x) => ['base', 'warning', 'error', 'success', 'primary'].includes(x)
      },
      position: {
        type: String,
        required: false,
        default: 'top-right',
        validator: (x) =>
          [
            'bottom-right',
            'bottom-left',
            'top-right',
            'top-left',
            'top-middle',
            'bottom-middle'
          ].includes(x)
      },
      message: {
        type: String,
        required: true
      }
    }
  }
  