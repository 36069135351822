import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import 'bootstrap';
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import VueMyToasts from 'vue-my-toasts'
import 'vue-my-toasts/dist/vue-my-toasts.css'
import mgToast from './components/mg-toast/mg-toast'

Vue.use(VueMyToasts, {
  component: mgToast,
  options: {
    position: 'bottom-right'
  }
})

Vue.use(ModalPlugin)
Vue.use(ToastPlugin)

const requireComponent = require.context(
  './global-components',
  false,
  /[A-Z-]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
const componentConfig = requireComponent(fileName)
const componentName = upperFirst(
  camelCase(
  fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, '')
  )
)
Vue.component(
  componentName,
  componentConfig.default || componentConfig
)
})





Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
