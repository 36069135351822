<template>

  <div>
    <template v-if="$store.state.auth.user.id">
      <navigation>
        <!-- <template #page-content>
          <router-view />
        </template> -->
      </navigation>
    </template>

    <template v-else>
      <router-view />
    </template>

  </div>

</template>

<script>
import axios from 'axios';
import navigation from './components/navigation/navigation';
export default {

  data: () => ({
  }),

  methods: {

    checkUser() {
      var identifier = this.getCookie('identifier');
      var securitytoken = this.getCookie('securitytoken');
      if (!this.$store.state.auth.user.id || !identifier || !securitytoken) {
        axios
          .post(process.env.VUE_APP_ROOT_API + '/auth.php', {
            req: 'checkLogin',
            identifier: identifier,
            securitytoken: securitytoken
          },
          {
              withCredentials: true
          })
          .then((response) => {
            if (!response.data.output.error) {
              this.$store.state.auth.user.id = response.data.output.userid;
            } else {
              this.$store.state.auth.user.id = null;
              if (this.$route.name !== 'Login') {
                this.$router.push({ name: 'Login' });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    }

  },

  components: {
    navigation
  },

  mounted() {
    this.checkUser();
  }

}

</script>

<style lang="scss">
@import '/assets/scss/base.scss';
</style>
